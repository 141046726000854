import {createRouter, createWebHashHistory} from 'vue-router'
import App from '../App'
import MiniPageSettings from '../views/Mini/PageSettings.vue'
import MiniLinkSettings from '../views/Mini/MiniLinkSettings.vue'
import MiniOrderList from '../views/Mini/MiniOrderList.vue'

const routes = [
  {
    path: '/mini',
    name: 'Home',
    component: App,
    children: [
      {path: '/mini/back', name: 'MiniPageSettings', component: MiniPageSettings,},
      {path: '/mini/link', name: 'MiniLinkSettings', component: MiniLinkSettings,},
      {path: '/mini/order', name: 'MiniOrderList', component: MiniOrderList,},
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
