<template>
  <div class="PageSettings">
    <n-scrollbar class="pageScroll">
      <n-card :bordered="false">
        <template #header>
          <div class="label-primary">
            头部banner（单张图片时，不轮播）
          </div>
        </template>
        <template #default>
          <div class="banner-list">
            <picture-list v-model:list="bannerList" @edit="i=> picEdit(i, '1')" @del="i=>picDel(i, '1')"
                          @move-left="i=>picMove(i, '1')"/>

            <div @click.prevent="onAddPicByDrawer('1')">
              <div class="new-one">
                <n-icon size="48" :depth="3">
                  <cloud-upload-outlined/>
                </n-icon>
              </div>
              <div class="new-text">
                上传图片
              </div>
            </div>
          </div>
        </template>
      </n-card>

      <n-card :bordered="false" v-for="(i, k) in contentList" :key="k">
        <template #header>
          <n-space style="display: flex">
            <div class="label-primary">
              内容样式{{ k + 1 }}（单张图片不进行轮播）
            </div>
            <n-space :size="5" align="center">
              <n-button-group size="small">
                <n-button clor="#8a2be2" type="default" round title="显示" v-if="i.isShow"
                          @click.prevent="onContentWatchChange(i, k, false)">
                  <template #icon>
                    <n-icon>
                      <eye-outlined/>
                    </n-icon>
                  </template>
                </n-button>
                <n-button clor="#8a2be2" type="default" round title="隐藏" v-else
                          @click.prevent="onContentWatchChange(i, k, true)">
                  <template #icon>
                    <n-icon>
                      <eye-invisible-twotone/>
                    </n-icon>
                  </template>
                </n-button>
                <n-button clor="#8a2be2" type="default" title="新增" @click.prevent="onAddNewContent">
                  <template #icon>
                    <n-icon>
                      <plus-square-twotone/>
                    </n-icon>
                  </template>
                </n-button>
                <n-button clor="#8a2be2" type="default" title="移除" @click.prevent="onDelContent(i, k)">
                  <template #icon>
                    <n-icon>
                      <minus-square-twotone/>
                    </n-icon>
                  </template>
                </n-button>
                <n-button clor="#8a2be2" type="default" round title="上移" :disabled="k === 0"
                          @click.prevent="onContentMoveUp(i, k)">
                  <template #icon>
                    <n-icon>
                      <arrow-up-outlined/>
                    </n-icon>
                  </template>
                </n-button>
              </n-button-group>
            </n-space>
          </n-space>
        </template>
        <template #default>
          <n-space vertical style="padding: 0 20px">
            <div :class="i.hover?'show-border': null" class="hide-border">
              <!--            标题-->
              <n-space :size="30">
                <n-space align="center">
                  <span>标题</span>
                  <n-input size="small" v-model:value="i.title" clearable></n-input>
                </n-space>
                <n-space align="center">
                  <span>更多</span>
                  <n-radio-group size="small" v-model:value="i.show" @update:value="onItemChange(i)">
                    <n-radio value="1">不显示</n-radio>
                    <n-radio value="2">显示</n-radio>
                  </n-radio-group>
                </n-space>
                <n-space v-if="i.show === '2'">
                  <span style="color: red">*</span>
                  <n-select
                      style="width: 193px;"
                      size="small"
                      placeholder="选择课程类型"
                      :options="linkTypeList"
                      v-model:value="i.linkType"

                  >
                  </n-select>
                </n-space>
                <n-space v-if="i.linkType">
                  <span style="color: red">*</span>
                  <n-button type="primary" text @click.prevent="onShowCourse2(k)" v-if="i.linkType">
                    {{ i.linkId ? i.linkName : (i.linkType === '4' ? '选择内容' : '选择课程') }}
                  </n-button>
                </n-space>
                <n-space align="center">
                  <n-button type="primary" size="small" @mouseover="i.hover = true" @mouseleave="i.hover = false"
                            @click="onSaveContentItem(i)">保存
                  </n-button>
                </n-space>
              </n-space>
            </div>
            <div class="label-primary">
              新增图片
            </div>
            <div class="banner-list">
              <picture-list v-if="i.pictureList" v-model:list="i.pictureList" @edit="a=> picEdit(a, '2', i)"
                            @del="a=>picDel(a, '2', i)"
                            @move-left="a=>picMove(a, '2', i)"/>

              <div @click.prevent="onAddPicByDrawer('2', i)">
                <div class="new-one">
                  <n-icon size="48" :depth="3">
                    <cloud-upload-outlined/>
                  </n-icon>
                </div>
                <div class="new-text">
                  上传图片
                </div>
              </div>
            </div>
          </n-space>
        </template>
      </n-card>

      <n-card :bordered="false" :class="footerHover? 'show-border': null" class="hide-border">
        <template #header>
          <n-space align="center">
            <div class="label-primary">
              底部悬浮窗
            </div>
            <n-button clor="#8a2be2" type="default" round title="显示" size="small" v-if="footerInfo.isShow"
                      @click.prevent="onFooterShow(false)">
              <template #icon>
                <n-icon>
                  <eye-outlined/>
                </n-icon>
              </template>
            </n-button>
            <n-button clor="#8a2be2" type="default" round title="隐藏" v-else @click.prevent="onFooterShow(true)">
              <template #icon>
                <n-icon>
                  <eye-invisible-twotone/>
                </n-icon>
              </template>
            </n-button>
          </n-space>
        </template>
        <template #default>
          <n-space vertical style="padding: 0 20px" :size="[0 , 20]">
            <n-space>
              <span>地图（750*200）</span>
              <span>
              <div>
                <div v-if="footerInfo.pictureUrl">
                <n-space vertical>
                  <img :src="footerInfo.pictureUrl" alt="" style="width: 190px; height: 100px; object-fit: cover"
                       v-viewer>
                  <n-button type="primary" text @click="footerInfo.pictureUrl = null">删除</n-button>
                </n-space>
              </div>
              <n-upload
                  v-else
                  :action="uploadInfo.dwurl"
                  :data="dataTrans"
                  accept="image/*"
                  :on-finish="uploadFinish2"
                  name="unfile"
                  :show-file-list="false"
              >
                <n-upload-dragger>
                  <div>
                    <n-icon size="48" :depth="3">
                      <cloud-upload-outlined/>
                    </n-icon>
                  </div>
                  <n-text style="font-size: 16px;">点击或者拖动文件到该区域来上传</n-text>
                    <br/>
                    <n-text style="font-size: 12px; color: #FF9900">大小不超过2M</n-text>
                </n-upload-dragger>

              </n-upload>
              </div>
            </span>
            </n-space>

            <n-space>
              <span>选择课程类型</span>
              <n-select
                  style="width: 193px;"
                  size="small"
                  v-model:value="footerInfo.linkType"
                  placeholder="选择课程类型"
                  :options="courseTypeList"
                  :on-update:value="r=>onValueChange(r, footerInfo, 'linkType', 'linkName、linkId')"
              >
              </n-select>
            </n-space>

            <n-space v-if="footerInfo.linkType">
              <span>关联课程</span>
              <n-button type="primary" text @click="onShowCourse3">
                {{ footerInfo.linkId ? footerInfo.linkName : (footerInfo.linkType === '4' ? '选择内容' : '选择课程') }}
              </n-button>
            </n-space>
            <n-space>
              <span>是否可手动关闭</span>
              <n-radio-group v-model:value="footerInfo.isShut">
                <n-radio value="1">是</n-radio>
                <n-radio value="2">否</n-radio>
              </n-radio-group>
            </n-space>

            <n-space>
              <n-button type="primary" size="small" @mouseover="footerHover = true" @mouseleave="footerHover = false"
                        :loading="footerSaveLoading" @click.prevent="onSaveFooter">保存
              </n-button>
              <n-button size="small" @click.prevent="getFooter">
                取消
              </n-button>
            </n-space>

          </n-space>
        </template>
      </n-card>

    </n-scrollbar>
    <n-drawer v-model:show="visible" :width="700" placement="left" :mask-closable="false">
      <n-drawer-content>
        <template #header>
          <span style="font-weight: bold">
            新增/编辑
          </span>
        </template>
        <template #default>


          <n-form
              :model="formInfo"
              :rules="rules"
              ref="formRef"
              label-placement="left"
              label-align="left"
              require-mark-placement="left"
              :label-width="160"
              size="small"
              :style="{maxWidth: '640px', marginTop: '30px'}"
          >
            <n-form-item path="img">
              <div v-if="formInfo.img">
                <n-space vertical>
                  <img :src="formInfo.img" alt="" style="width: 190px; height: 100px; object-fit: cover" v-viewer>
                  <n-button type="primary" text @click="formInfo.img = null">删除</n-button>
                </n-space>
              </div>
              <n-upload
                  v-else
                  :action="uploadInfo.dwurl"
                  :data="dataTrans"
                  accept="image/*"
                  :on-finish="uploadFinish"
                  :on-before-upload="beforeUpload"
                  name="unfile"
                  list-type="image-card"
                  @preview="handlePreview"
                  :default-file-list="defaultFileList"
              >
                <div style="font-size: 12px">
                  <div>点击上传</div>
                  <div>大小不超过2M</div>
                </div>
              </n-upload>
            </n-form-item>
            <n-form-item label="是否跳转链接" path="isLink">
              <n-radio-group v-model:value="formInfo.isLink">
                <n-space>
                  <n-radio value="1">否</n-radio>
                  <n-radio value="2">是</n-radio>
                </n-space>
              </n-radio-group>
            </n-form-item>

            <n-form-item label="链接类型" path="linkType" v-if="formInfo.isLink === '2'">
              <n-select
                  placeholder="请选择链接类型"
                  :options="linkTypeList"
                  v-model:value="formInfo.linkType"
                  :on-update:value="r=>onValueChange(r, formInfo, 'linkType', 'courseId、courseName')"
              >
              </n-select>
            </n-form-item>

            <n-form-item label="关联课程/内容" path="courseId" v-if="formInfo.isLink === '2'">
              <n-space>
                <n-button type="primary" text @click.prevent="onShowCourse" v-if="formInfo.linkType">
                  {{ formInfo.courseId ? formInfo.courseName : (formInfo.linkType === '4' ? '选择内容' : '选择课程') }}
                </n-button>
              </n-space>
            </n-form-item>
          </n-form>
        </template>
        <template #footer>
          <n-space>
            <n-button type="primary" size="small" :loading="saveLoading" @click.prevent="onPicSave">确定</n-button>
            <n-button strong tertiary size="small" @click.prevent="visible = false">关闭</n-button>
          </n-space>
        </template>
      </n-drawer-content>
    </n-drawer>

    <n-modal
        v-model:show="showPicture"
        preset="card"
        style="width: 600px;"
        title=""
        to="body"
    >
      <img :src="previewImageUrl" style="width: 100%;" alt=""/>
    </n-modal>

    <n-modal
        v-model:show="showCourse"
        preset="card"
        style="width: 1000px;"
        title=""
        to="body"
        :mask-closable="false"
    >
      <template #header>
        <span style="font-weight: bold">关联课程/内容</span>
      </template>
      <template #default>
        <course-relate :is-course="formInfo.linkType !== '4'" :link-type="formInfo.linkType" @relate="onRelate"
                       ref="courseRef"/>
      </template>
    </n-modal>

    <n-modal
        v-model:show="showCourse2"
        preset="card"
        style="width: 1000px;"
        title=""
        to="body"
        :mask-closable="false"
    >
      <template #header>
        <span style="font-weight: bold">关联课程/内容</span>
      </template>
      <template #default>
        <course-relate :is-course="linkType !== '4'" :link-type="linkType" @relate="onRelate2" ref="courseRef2"/>
      </template>
    </n-modal>

    <n-modal
        v-model:show="showCourse3"
        preset="card"
        style="width: 1000px;"
        title=""
        to="body"
        :mask-closable="false"
    >
      <template #header>
        <span style="font-weight: bold">关联课程</span>
      </template>
      <template #default>
        <course-relate :is-course="true" :link-type="footerInfo.linkType" @relate="onRelate3" ref="courseRef3"/>
      </template>
    </n-modal>

  </div>
</template>

<script>
import PictureList from "../../components/Mini/PictureList";
import {defineComponent, nextTick, onMounted, reactive, ref, toRefs, watch} from "vue";
import {
  CloudUploadOutlined,
  EyeOutlined,
  EyeInvisibleTwotone,
  PlusSquareTwotone,
  ArrowUpOutlined,
  MinusSquareTwotone
} from '@vicons/antd'
import uploadInfo from '../../utils/upload'
import moment from "moment";
import CourseRelate from "../../components/Mini/CourseRelate";
import server from "../../utils/api";
import {useLoadingBar, useMessage} from "naive-ui";

export default defineComponent({
  name: "PageSettings",
  components: {
    CourseRelate,
    PictureList,
    CloudUploadOutlined,
    EyeOutlined,
    EyeInvisibleTwotone,
    PlusSquareTwotone,
    ArrowUpOutlined,
    MinusSquareTwotone,
  },
  setup() {
    const formRef = ref(null)
    let place = ref(null);
    const courseRef = ref(null)
    const courseRef2 = ref(null)
    const courseRef3 = ref(null)
    const message = useMessage()
    const loadingBar = useLoadingBar()
    let state = reactive({
      bannerList: [],
      contentList: [],
      visible: false,
      showPicture: false,
      showCourse: false,
      showCourse2: false,
      showCourse3: false,
      defaultFileList: [],
      previewImageUrl: null,
      linkTypeList: [
        {label: '体验课', value: '1'},
        {label: '试听课', value: '2'},
        {label: '引流课', value: '3'},
        {label: '更多内容', value: '4'},
      ],
      formInfo: {
        styleId: null,
        img: null,
        isLink: '1',
        linkType: null,
        courseId: null,
        courseName: null,
        pictureId: null,
      },
      footerInfo: {
        linkId: null,
        linkType: null,
        pictureId: null,
        pictureUrl: null,
        linkName: null,
        isShut: '1',
      },
      footerHover: false,
      footerSaveLoading: false,
      saveLoading: false,
      contentIndex: null,
      isEdit: false,
      isCourse: false,
      linkType: '1',
    })
    const courseTypeList = [
      {label: '体验课', value: '1'},
      {label: '试听课', value: '2'},
      {label: '引流课', value: '3'},
    ]
    const rules = {
      isLink: [
        {required: true, message: '请选择是否跳转链接'},
      ],
      linkType: {required: true, trigger: ['blur', 'change'], message: '请选择链接类型'},
      courseId: {required: true, message: '请选择课程或内容链接'}
    }
    const uploadFinish = ({file, event}) => {
      file.url = `${uploadInfo.dwurl}mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`
      state.formInfo.img = file.url
    }
    const uploadFinish2 = ({file, event}) => {
      file.url = `${uploadInfo.dwurl}mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`
      state.footerInfo.pictureUrl = file.url
    }
    const beforeUpload = ({file, fileList}) => {
      if (fileList.length === 1) {
        return false
      }
    }
    const handlePreview = (file) => {
      console.log(file);
      const {url} = file
      state.previewImageUrl = url
      state.showPicture = true
    }
    const dataTrans = ({file}) => {
      return {
        key: `mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`,
        id: file.id,
        name: file.name,
        type: file.type,
        lastModifiedDate: file.file.lastModifiedDate,
        size: file.file.size,
        ...uploadInfo.params,
        file: file.file,
      }
    }

    onMounted(async () => {
      loadingBar.start();
      await getHeader()
      await getContent()
      await getFooter()
      loadingBar.finish();
    })

    const getHeader = async () => {
      const r = await server.get('/dwart/external/smallProgram/queryHeadBanner')
      state.bannerList = r;
    }

    const getContent = async () => {
      const r = await server.get('/dwart/external/smallProgram/queryContentStyle')
      state.contentList = r.map(v => {
        return {...v, show: v.isAccording ? '2' : '1', linkType: v.linkType && v.linkType.toString()}
      });
    }

    const getFooter = async () => {
      loadingBar.start();
      const r = await server.get('/dwart/external/smallProgram/queryFloatingWindow')
      state.footerInfo = {
        linkId: r.linkId,
        linkType: r.linkType && r.linkType.toString(),
        pictureId: r.pictureId,
        pictureUrl: r.pictureUrl,
        linkName: r.linkName,
        isShut: r.isShut ? '1' : '2',
      };
      loadingBar.finish();
    }

    const getSave = async () => {
      const r = await server.post('/dwart/external/smallProgram/addPicture', {
        pictureId: state.formInfo.pictureId,
        styleId: state.formInfo.styleId,
        linkId: state.formInfo.courseId,
        pictureType: place,
        pictureUrl: state.formInfo.img,
        isJump: state.formInfo.isLink === '2',
        linkType: state.formInfo.linkType,
      })
      if (r) {
        message.success('保存成功')
      }
      if (place === '1') {
        await getHeader()
      } else if (place === '2') {
        await getContent()
      }
      state.visible = false
    }

    const methods = {
      onAddPicByDrawer: (type, row) => {
        place = type;
        state.visible = true
        state.formInfo = {
          styleId: type === '2' ? row.styleId : null,
          img: null,
          isLink: '1',
          linkType: null,
          courseId: null,
          courseName: null,
          pictureId: null,
        }
      },
      onPicSave: (e) => {
        e.preventDefault();
        formRef.value.validate(err => {
          if (!err) {
            getSave();
          }
        })
      },
      picEdit: async (item, type, style = null) => {
        place = type
        loadingBar.start();
        const r = await server.get('/dwart/external/smallProgram/echoPicture', {params: {pictureId: item.pictureId}})
        state.formInfo = {
          styleId: r.styleId,
          img: r.pictureUrl,
          isLink: r.isJump ? '2' : '1',
          linkType: r.linkType && r.linkType.toString(),
          courseId: r.linkId,
          courseName: r.linkName,
          pictureId: r.pictureId,
        }
        loadingBar.finish();
        state.visible = true
      },
      picDel: async (item, type) => {
        loadingBar.start();
        const r = await server.get('/dwart/external/smallProgram/delectPicture', {params: {pictureId: item.pictureId}})
        if (r) {
          message.success('删除成功')
        }
        if (type === '1') {
          await getHeader();
        } else if (type === '2') {
          await getContent();
        }
        loadingBar.finish();
      },
      picMove: async (item, type) => {
        loadingBar.start();
        const r = await server.get('/dwart/external/smallProgram/leftShiftPicture', {params: {pictureId: item.pictureId}})
        if (type === '1') {
          await getHeader();
        } else if (type === '2') {
          await getContent();
        }
        loadingBar.finish();
      },
      onAddNewContent: async () => {
        loadingBar.start();
        const r = await server.get('/dwart/external/smallProgram/addContentStyle')
        state.contentList.push({
          ...r,
          show: r.isAccording ? '2' : '1',
          linkType: r.linkType && r.linkType.toString()
        });
        loadingBar.finish()
      },
      onDelContent: async (row, k) => {
        loadingBar.start();
        const r = await server.get('/dwart/external/smallProgram/deleteContentStyle', {
          params: {styleId: row.styleId,}
        })
        loadingBar.finish();
        if (r) {
          message.success('删除成功')
          state.contentList.splice(k, 1);
        } else {
          message.error('删除失败')
        }
      },
      onContentWatchChange: async (row, k, show) => {
        loadingBar.start();
        const r = await server.post('/dwart/external/smallProgram/hiddenContentStyle', {
          styleId: row.styleId,
          isShow: show
        })
        loadingBar.finish()
        if (r) {
          message.success(show ? '内容设置为显示' : '内容设置为隐藏');
          row.isShow = show
        } else {
          message.error('设置失败')
        }
      },
      onContentMoveUp: async (row, k) => {
        loadingBar.start()
        const r = await server.get('/dwart/external/smallProgram/shiftUpContentStyle', {
          params: {styleId: row.styleId}
        })
        loadingBar.finish()
        if (r) {
          await getContent();
        } else {
          message.error('上移失败')
        }
      },
      onShowCourse: async (index = null) => {
        state.showCourse = true;
        await nextTick(() => {
          courseRef.value.initTable();
        })
      },
      onShowCourse2: async (index = null) => {
        state.contentIndex = index
        state.linkType = state.contentList[state.contentIndex].linkType;
        state.showCourse2 = true;
        await nextTick(() => {
          courseRef2.value.initTable();
        })
      },
      onShowCourse3: async () => {
        state.showCourse3 = true;
        await nextTick(() => {
          courseRef3.value.initTable();
        })
      },
      onRelate: (row) => {
        if (state.formInfo.linkType === '4') {
          state.formInfo.courseName = row.pageName
          state.formInfo.courseId = row.contentId
        } else {
          state.formInfo.courseName = row.courseName
          state.formInfo.courseId = row.courseId
        }
        state.showCourse = false;
      },
      onRelate2: (row) => {
        if (state.contentList[state.contentIndex].linkType === '4') {
          state.contentList[state.contentIndex].linkId = row.contentId
          state.contentList[state.contentIndex].linkName = row.pageName
        } else {
          state.contentList[state.contentIndex].linkId = row.courseId
          state.contentList[state.contentIndex].linkName = row.courseName
        }
        state.showCourse2 = false;
      },
      onRelate3: (row) => {
        console.log(row);
        state.footerInfo.linkName = row.courseName
        state.footerInfo.linkId = row.courseId
        state.showCourse3 = false;
      },
      onItemChange: (row) => {
        if (row.show === '1') {
          row.linkType = null;
          row.linkId = null;
          row.linkName = null;
        }
      },
      onSaveContentItem: async (row) => {
        if (row.show === '2') {
          if (row.linkType === null || row.linkId === null || row.linkName === null) {
            message.error('请填写完整后提交')
            return;
          }
        }
        const r = await server.post('/dwart/external/smallProgram/updateContentStyle', {
          "styleId": row.styleId,//内容样式id
          "title": row.title,//标题
          "isAccording": row.show === '2',//是否显示更多  true：显示  false：不显示
          "linkType": row.linkType,//链接类型  1体验课  2试听课  3引流课  4更多内容
          "linkId": row.linkId//链接id
        })
        if (r) {
          message.success('保存成功')
        } else {
          message.error('保存失败，请重试')
        }
      },
      onFooterShow: async (show) => {
        loadingBar.start();
        const r = await server.post('/dwart/external/smallProgram/hiddenFloatingWindow', {
          pictureId: state.footerInfo.pictureId,
          isShow: show,
        })
        loadingBar.finish();
        if (r) {
          message.success(show ? '内容设置为显示' : '内容设置为隐藏')
          state.footerInfo.isShow = show
        }
      },
      onSaveFooter: async () => {
        state.footerSaveLoading = true;
        const r = await server.post('/dwart/external/smallProgram/addFloatingWindow', {
          pictureId: state.footerInfo.pictureId,
          pictureUrl: state.footerInfo.pictureUrl,
          linkType: state.footerInfo.linkType,
          linkId: state.footerInfo.linkId,
          isShut: state.footerInfo.isShut === '1',
        })
        if (r){
          state.footerSaveLoading = false;
          message.success('保存成功')
        }
      },
      onValueChange(value, data, key, clearStr){
        data[key] = value;
        clearStr.split('、').forEach(v=> {
          data[v] = null;
        })
      }
    }

    return {
      formRef,
      courseRef,
      courseRef2,
      courseRef3,
      rules,
      ...toRefs(state),
      beforeUpload,
      uploadFinish,
      uploadFinish2,
      dataTrans,
      handlePreview,
      getFooter,
      courseTypeList,
      ...methods,
    }
  },
  data() {
    return {
      uploadInfo: uploadInfo,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {

    },
  },
})
</script>

<style lang="scss" scoped>
.PageSettings {
  .banner-list {
    display: flex;
    flex-wrap: wrap;
  }

  .new-one {
    width: 290px;
    height: 140px;
    border: 1px solid #CCC;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .2s;
    margin: 10px;

    &:hover {
      color: #FF9900;
      border-color: #FF9900;

      i {
        color: #FF9900;
      }
    }
  }

  .new-text {
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    color: #FF9900;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
  }

  .hide-border {
    padding: 20px;
    border: 1px solid #FFF;
    border-radius: 10px;
    box-sizing: border-box;
    transition: all 300ms;
  }

  .show-border {
    border-color: #FF9900;
  }
}
</style>
