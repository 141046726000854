<template>
  <n-loading-bar-provider>
    <n-message-provider>
      <n-config-provider :date-locale="dateZhCN" :theme-overrides="themeOverrides" :locale="zhCN">
        <router-view></router-view>
      </n-config-provider>
    </n-message-provider>
  </n-loading-bar-provider>
</template>

<script>
import {defineComponent, onMounted} from "vue";
import {NConfigProvider, zhCN, dateZhCN, NLoadingBarProvider, NMessageProvider} from "naive-ui";

export default defineComponent({
  name: 'App',
  components: {NConfigProvider, NLoadingBarProvider, NMessageProvider},
  data() {
    return {
      themeOverrides: {
        common: {
          primaryColor: '#FF9900'
        },
      },
      zhCN,
      dateZhCN,
    }
  },
  setup() {
    // const getMsg = (e) => {
    //   console.log(e);
    //   let data = e.data
    //   switch (data.type) {
    //     case 'msg':
    //       setInitData(data.params);
    //   }
    // }
    // const setInitData = (params) => {
    //   localStorage.setItem('token', params.token);
    // }
    // const init = async () => {
    //   window.addEventListener('message', getMsg)
    // }
    // onMounted(() => {
    //   init()
    // })

    return {}
  }
})
</script>


<style lang="scss">

</style>
