<template>
  <div class="MiniOrderList">
    <n-form
        size="small"
        label-placement="left"
        label-align="left"
        require-mark-placement="left"
        :label-width="100"
        inline
        :model="model"
        ref="formRef"
    >
      <n-form-item label="课程类型">
        <n-select
            v-model:value="model.linkType"
            placeholder="选择课程类型"
            :options="courseTypeList"
            style="width: 193px"
            clearable
        >
        </n-select>
      </n-form-item>

      <n-form-item label="课程名称">
        <n-input v-model:value="model.courseName" clearable @keydown.enter.prevent="initTable"/>
      </n-form-item>

      <n-form-item label="姓名电话">
        <n-input v-model:value="model.nameorphone" clearable @keydown.enter.prevent="initTable"/>
      </n-form-item>


      <n-form-item>
        <n-space>
          <n-button type="primary" @click.prevent="initTable">查询</n-button>
          <n-button @click.prevent="onReset">重置</n-button>
          <n-button @click.prevent="onExport">导出</n-button>
        </n-space>
      </n-form-item>
    </n-form>

    <n-space vertical>
      <n-data-table
          size="small"
          :columns="columns"
          :data="data"
          :loading="loading"
          :row-key="rowKey"
      />
      <div style="display: flex; justify-content: flex-end">
        <n-pagination :page-sizes="pagination.pageSizes" :page-size="pagination.pageSize"
                      :item-count="pagination.itemCount" show-quick-jumper show-size-picker
                      :prefix="pagination.prefix"
                      :on-update:page="onPageChange"
                      :on-update:page-size="onPageSizeChange"
        />
      </div>
    </n-space>


  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import server from "../../utils/api";
import {useLoadingBar} from "naive-ui";
import downLoadFile from "../../utils/download";

export default defineComponent({
  name: "MiniOrderList",
  setup() {
    const loadingBar = useLoadingBar()

    let state = reactive({
      model: {
        linkType: null,
        courseName: '',
        nameorphone: '',
      },
      data: [],
      loading: false,
      rowKey(rowData) {
        return rowData.column1
      },
      courseTypeList: [
        {label: '体验课', value: '1'},
        {label: '试听课', value: '2'},
        {label: '引流课', value: '3'},
      ],
      pagination: {
        page: 1,
        pageSize: 10,
        itemCount: 0,
        pageSizes: [10, 20, 50, 100],
        prefix({itemCount}) {
          return `合计${itemCount}条`
        }
      },
    })

    const columns = [
      {title: '姓名', key: 'name', align: 'center'},
      {title: '电话', key: 'phone', align: 'center'},
      {title: '宝贝年龄', key: 'age', align: 'center'},
      {title: '宝贝性别', key: 'sex', align: 'center', render: (row) => {
        switch (row.sex){
          case 1:
            return '男';
          case 2:
            return '女';
          default:
            return  '-'
        }
        }},
      // {title: '居住小区', key: 'address', align: 'center'},
      {title: '课程名称', key: 'courseName', align: 'center'},
      {
        title: '课程类型', key: 'courseType', align: 'center', render: (row) => {
          switch (row.courseType) {
            case 1:
              return '体验课';
            case 2:
              return '试听课';
            case 3:
              return '引流课';
            default:
              return '-'
          }
        }
      },
      {title: '订单费用', key: 'payable', align: 'center', render: (row) => {return `￥${row.payable}`}},
      {title: '订单编号', key: 'orderNo', align: 'center'},
      {title: '支付流水号', key: 'flowNo', align: 'center'},
      {title: '下单时间', key: 'orderTime', align: 'center'},
    ]

    const getTable = async () => {
      state.loading = true;
      const r = await server.post('/dwart/external/smallProgram/queryOrderDetailsList', {
        "courseType": state.model.linkType,//课程类型
        "courseName": state.model.courseName,//课程名称
        "nameOrPhone": state.model.nameorphone,//姓名或手机号
        "page": state.pagination.page,//开始页
        "size": state.pagination.pageSize//每页显示的记录数
      })
      state.loading = false;
      state.pagination.itemCount = parseInt(r.count)
      state.data = r.entity;
    }

    onMounted(() => {
      getTable();
    })

    let methods = {
      initTable() {
        // 初始化查询
        state.pagination.page = 1;
        state.pagination.pageSize = 10;
        getTable()
      },
      onExport() {
        downLoadFile('/dwart/external/smallProgram/queryOrderDetailsList/excel', {
          "courseType": state.model.linkType,//课程类型
          "courseName": state.model.courseName,//课程名称
          "nameOrPhone": state.model.nameorphone,//姓名或手机号
        }, '订单列表.xls', loadingBar, 'post');
      },
      onReset() {
        state.model = {
          linkType: null,
          courseName: '',
          nameorphone: '',
        }
      },
      onPageChange: (page) => {
        state.pagination.page = page;
        getTable()
      },
      onPageSizeChange: (pageSize) => {
        state.pagination.pageSize = pageSize;
        getTable()
      },
    }

    return {
      ...toRefs(state),
      ...methods,
      columns,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {

    },
  },
})
</script>

<style lang="scss" scoped>
.MiniOrderList {

}
</style>
