<template>
  <div class="ContentSetting">
    <n-space vertical>
      <n-button type="primary" size="small" @click.prevent="onAddOne">新增内容</n-button>
      <n-card :bordered="false" :segmented="{content: 'soft'}">
        <template #header>
          <div style="text-align: center">
            内容详情
          </div>
        </template>
        <template #default>
          <n-space vertical>
            <n-data-table
                size="small"
                :columns="columns"
                :data="data"
                :loading="loading"
                :row-key="rowKey"
            />
            <div style="display: flex; justify-content: flex-end">
              <n-pagination :page-sizes="pagination.pageSizes" :page-size="pagination.pageSize"
                            :item-count="pagination.itemCount" show-quick-jumper show-size-picker
                            :prefix="pagination.prefix"
                            :on-update:page="onPageChange"
                            :on-update:page-size="onPageSizeChange"
              />
            </div>
          </n-space>
        </template>
      </n-card>
    </n-space>

    <n-drawer v-model:show="visible" :width="700" placement="left" :mask-closable="false">
      <n-drawer-content>
        <template #header>
          <span style="font-weight: bold">
            新增/编辑
          </span>
        </template>
        <template #footer>
          <n-space>
            <n-button type="primary" size="small" :loading="drawerLoading" @click.prevent="onSave">保存</n-button>
            <n-button strong tertiary size="small" @click.prevent="visible = false">取消</n-button>
          </n-space>
        </template>
        <template #default>
          <n-form
              size="small"
              :model="form"
              ref="formRef"
              label-placement="left"
              label-align="left"
              require-mark-placement="left"
              :label-width="160"
              :style="{maxWidth: '640px'}"
              :rules="rules"
          >
            <n-form-item label="页面名称" path="name">
              <n-input v-model:value="form.name" clearable/>
            </n-form-item>
            <n-form-item label="上传图片" path="srcList">
              <n-space vertical>
                <draggable v-model="form.srcList" tag="transition-group" handle=".mover" animation="300"  :component-data="{name:'fade'}" item-key="id">
                  <template #item="{element}">
                    <div v-viewer>
                      <img :src="element" alt="" style="width: 100%; height: 129px; object-fit: cover; cursor: pointer">
                      <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px">
                        <n-space>
                          <n-button type="primary" text @click="onDel(element)">删除</n-button>
                          <n-button type="primary" text @click="onMoveUp(element)">上移</n-button>
                        </n-space>
                        <div>
                          <n-icon style="cursor: move;" class="mover">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                 viewBox="0 0 512 512">
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="32" d="M176 112l80-80l80 80"></path>
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="32" d="M255.98 32l.02 448"></path>
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="32" d="M176 400l80 80l80-80"></path>
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="32" d="M400 176l80 80l-80 80"></path>
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="32" d="M112 176l-80 80l80 80"></path>
                              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="32" d="M32 256h448"></path>
                            </svg>
                          </n-icon>
                        </div>
                      </div>
                    </div>
                  </template>
                </draggable>
                <n-upload
                    :action="uploadInfo.dwurl"
                    :data="dataTrans"
                    accept="image/*"
                    :on-finish="uploadFinish"
                    name="unfile"
                    multiple
                    :show-file-list="false"
                >
                  <n-upload-dragger>
                    <div>
                      <n-icon size="48" :depth="3">
                        <cloud-upload-outlined/>
                      </n-icon>
                    </div>
                    <n-text style="font-size: 16px;">点击或者拖动文件到该区域来上传</n-text>
                    <br/>
                    <n-text style="font-size: 12px; color: #FF9900">大小不超过2M</n-text>
                  </n-upload-dragger>

                </n-upload>
              </n-space>
            </n-form-item>
          </n-form>
        </template>
      </n-drawer-content>
    </n-drawer>

  </div>
</template>

<script>
import {h, onMounted, reactive, ref, toRefs} from "vue";
import {NButton, NSwitch, useLoadingBar, useMessage} from "naive-ui";
import server from "../../utils/api";
import uploadInfo from "../../utils/upload";
import moment from "moment";
import {CloudUploadOutlined} from "@vicons/antd";
import draggable from 'vuedraggable'

export default {
  name: "ContentSetting",
  components: {CloudUploadOutlined, draggable},
  data() {
    return {
      uploadInfo,
    }
  },
  mounted() {
    this.init();
  },
  setup() {
    let formRef = ref(null)
    const message = useMessage()
    const loadingBar = useLoadingBar()
    const columns = [
      {
        title: '页面名称',
        key: 'pageName',
        align: 'center'
      },
      {
        title: '创建时间',
        key: 'createTime',
        align: 'center',
      },
      {
        title: '状态',
        key: '',
        align: 'center',
        render(row) {
          return h(
              NSwitch,
              {
                value: row.isOpen,
                "onUpdate:value": function (e) {
                  onSwitchChange(row, e)
                },
                size: 'small',
                loading: row.loading,
              },
          )
        }
      },
      {
        title: '操作',
        key: 'address',
        align: 'center',
        render(row) {
          return h(
              NButton,
              {
                size: 'small',
                text: true,
                type: 'primary',
                onClick: () => onEdit(row),
              },
              {
                default: () => '编辑'
              }
          )
        }
      }
    ]
    const onEdit = (row) => {
      getEditInfo(row);
    }

    const onSwitchChange = async (row, e) => {
      row.loading = true;
      const r = await server.post('/dwart/external/smallProgram/switchContentConfiguration', {
        contentId: row.contentId,
        isOpen: e,
      });
      if (r) {
        row.isOpen = e;
      }
      row.loading = false;
    }

    let state = reactive({
      pagination: {
        page: 1,
        pageSize: 10,
        itemCount: 0,
        pageSizes: [10, 20, 50, 100],
        prefix({itemCount}) {
          return `合计${itemCount}条`
        }
      },
      rowKey(rowData) {
        return rowData.column1
      },
      loading: false,
      drawerLoading: false,
      data: [],
      visible: false,
      form: {
        id: null,
        name: null,
        srcList: [],
      }
    })
    const rules = {
      name: {required: true, message: '请输入课程名称', trigger: ['blur', 'change'],},
      srcList: {required: true, message: '请上传内容图片', type: 'array'}
    }

    onMounted(async () => {
      await getTable();
    })

    const getEditInfo = async (row) => {
      loadingBar.start();
      const r = await server.get('/dwart/external/smallProgram/echoContentConfiguration', {
        params: {
          contentId: row.contentId,
        }
      })
      state.form = {
        id: r.contentId,
        name: r.pageName,
        srcList: r.picture,
      }
      state.visible = true
      loadingBar.finish();
    }

    const getTable = async () => {
      state.loading = true;
      const r = await server.post('/dwart/external/smallProgram/queryContentConfiguration', {
        page: state.pagination.page,
        size: state.pagination.pageSize,
      })
      state.loading = false;
      state.pagination.itemCount = parseInt(r.count)
      state.data = r.entity;
    }
    const getSaveAddOrEdit = async () => {
      state.drawerLoading = true;
      const {id, name, srcList} = state.form;
      const r = await server.post('/dwart/external/smallProgram/addContentConfiguration', {
        contentId: id,
        pageName: name,
        picture: srcList
      })
      state.drawerLoading = false;
      state.visible = false;
      if (r){
        message.success('上传成功')
        await getTable();
      }
    }

    const methods = {
      onPageChange: (page) => {
        state.pagination.page = page;
        getTable()
      },
      onPageSizeChange: (pageSize) => {
        state.pagination.pageSize = pageSize;
        getTable()
      },
      onSave: (e) => {
        e.preventDefault();
        formRef.value.validate(err => {
          if (!err) {
            getSaveAddOrEdit();
          }
        })
      },
      onMoveUp: (item) => {
        let index = state.form.srcList.findIndex(v=> v === item);
        state.form.srcList.splice(index, 1);
        state.form.srcList.splice(index - 1, 0, item);
      },
      onDel: (item) => {
        let index = state.form.srcList.findIndex(v=> v === item);
        state.form.srcList.splice(index, 1);
      },
      dataTrans: ({file}) => {
        return {
          key: `mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`,
          id: file.id,
          name: file.name,
          type: file.type,
          lastModifiedDate: file.file.lastModifiedDate,
          size: file.file.size,
          ...uploadInfo.params,
          file: file.file,
        }
      },
      uploadFinish: ({file, event}) => {
        file.url = `${uploadInfo.dwurl}mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`
        state.form.srcList.push(file.url);
      },
      onAddOne: () => {
        state.form = {
          id: null,
          name: null,
          srcList: [],
        }
        state.visible = true
      }
    }

    return {
      formRef,
      ...toRefs(state),
      ...methods,
      columns,
      rules,
    }
  },
  methods: {
    async init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.ContentSetting {
  margin-top: 10px;
}
</style>
