import {createApp} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import './index.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import {
  create,
  NCard,
  NUpload,
  NUploadDragger,
  NIcon,
  NText,
  NDrawer,
  NDrawerContent,
  NButton,
  NModal,
  NInput,
  NForm,
  NFormItem,
  NSelect,
  NRadioGroup,
  NRadio,
  NSpace,
  NDataTable,
  NTabs,
  NTabPane,
  NTab,
  NDivider,
  NSwitch,
  NCollapseTransition,
  NInputNumber,
  NDatePicker,
  NButtonGroup,
  NScrollbar, NPagination,
} from "naive-ui";

const naive = create({
  components: [
    NCard,
    NUpload,
    NUploadDragger,
    NIcon,
    NText,
    NDrawer,
    NDrawerContent,
    NButton,
    NButtonGroup,
    NModal,
    NInput,
    NForm,
    NFormItem,
    NSelect,
    NRadioGroup,
    NRadio,
    NSpace,
    NDataTable,
    NTabs,
    NTabPane,
    NTab,
    NDivider,
    NSwitch,
    NCollapseTransition,
    NInputNumber,
    NDatePicker,
    NScrollbar,
    NPagination,
  ]
})


const getMsg = (e) => {
  console.log(e);
  let data = e.data
  switch (data.type) {
    case 'msg':
      setInitData(data.params);
  }
}

const setInitData = (params) => {
  localStorage.setItem('token', params.token);
}


window.addEventListener('message', getMsg)

createApp(App).use(store).use(router).use(naive).use(VueViewer).mount('#app')
