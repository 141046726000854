import axios from "axios";
import {useNotification} from "naive-ui";

if (process.env.NODE_ENV === 'test'){
  axios.defaults.baseURL = 'http://183.215.122.144:1553'
}else if (process.env.NODE_ENV === 'production'){
  axios.defaults.baseURL = 'http://www.dawei.art'
}

axios.defaults.withCredentials = false;
axios.defaults.timeout = 0 * 1000;
axios.defaults.headers = {
  "Accept": 'application/json', 'Content-Type': 'application/json',
  Authorization:localStorage.getItem('token'),
  mac_address: localStorage.getItem("macAd") ? localStorage.getItem("macAd") : null
}

const server = axios.create();

server.interceptors.request.use(request => {
  return request
})

server.interceptors.response.use(response => {
  if (response.config.responseType === 'blob' && response.status === 200) {
    return response.data;
  }
  const notify = useNotification();
  const res = response.data;
  if (res.code == 405){
    // 定向到一个提示登录页的地方
    let message = {
      code: 405,
    }
    window.parent.postMessage(message, '*');
    return new Error('登录失效');
  }
  if (res.message !== null && res.message.length > 0){
    notify.create({
      title: '请求错误',
      content: res.message,
      duration: 3000,
      closable: true,
    })
    return false;
  }
  if (res.success){
    return  res.data;
  }
})

export default server;
