<template>
  <div class="CourseRelate">
    <!--      关联课程-->
    <n-space vertical>
      <n-form
          size="small"
          label-placement="left"
          label-align="left"
          require-mark-placement="left"
          :label-width="100"
          inline
      >
        <n-form-item :label="isCourse?'课程名称': '页面名称'">
          <n-input v-model:value="name" clearable placeholder="" @keyup.enter.prevent="initTable"></n-input>
        </n-form-item>
        <n-form-item>
          <n-space>
            <n-button type="primary" @click.prevent="initTable">查询</n-button>
          </n-space>
        </n-form-item>
      </n-form>
      <n-data-table
          size="small"
          :columns="columns"
          :data="data"
          :loading="loading"
          :row-key="rowKey"
      />
      <div style="display: flex; justify-content: flex-end">
        <n-pagination :page-sizes="pagination.pageSizes" :page-size="pagination.pageSize"
                      :item-count="pagination.itemCount" show-quick-jumper show-size-picker
                      :prefix="pagination.prefix"
                      :on-update:page="onPageChange"
                      :on-update:page-size="onPageSizeChange"
        />
      </div>
    </n-space>
  </div>
</template>

<script>
import {h, reactive, toRefs} from "vue";
import {NButton} from "naive-ui";
import server from "../../utils/api";

export default {
  name: "CourseRelate",
  data() {
    return {}
  },
  props: {
    isCourse: {
      required: false,
      default: false,
      type: Boolean,
    },
    linkType: {
      required: false,
      default: '1',
      type: [String, null]
    }
  },
  mounted() {
    this.init();
  },
  setup(props, {emit}){
    let state = reactive({
      name: null,
      pagination: {
        page: 1,
        pageSize: 10,
        itemCount: 0,
        pageSizes: [10, 20, 50, 100],
        prefix({itemCount}) {
          return `合计${itemCount}条`
        }
      },
      data: [],
      loading: false,
      rowKey(rowData) {
        return rowData.column1
      },
    })
    const columns1 = [
      {
        title: '课程名称',
        key: 'courseName',
        align: 'center'
      },
      {
        title: '课程类型',
        key: 'courseType',
        align: 'center'
      },
      {
        title: '活动时间',
        key: 'activityTime',
        align: 'center',
      },
      {
        title: '创建时间',
        key: 'createTime',
        align: 'center',
      },
      {
        title: '单价',
        key: 'coursePrice',
        align: 'center',
      },
      {
        title: '操作',
        key: 'address',
        align: 'center',
        render(row){
          return h(
              NButton,
              {
                size: 'small',
                text: true,
                type: 'primary',
                onClick: () => onRelate(row),
              },
              {
                default: ()=> '关联课程'
              }
          )
        }
      }
    ]
    const columns2 = [
      {
        title: '页面名称',
        key: 'pageName',
        align: 'center',
      },
      {
        title: '创建时间',
        key: 'createTime',
        align: 'center',
      },
      {
        title: '操作',
        key: 'address',
        align: 'center',
        render(row){
          return h(
              NButton,
              {
                size: 'small',
                text: true,
                type: 'primary',
                onClick: () => onRelate(row),
              },
              {
                default: ()=> '关联内容'
              }
          )
        }
      }
    ]
    const onRelate = (row) => {
      emit('relate', row);
    }
    const getTable = async () => {
      state.loading = true
      let url, data;
      if (props.isCourse){
        url = '/dwart/external/smallProgram/queryCourseTypeConfiguration'
        data = {
          "courseType": props.linkType,//课程类型 1：体验课 2：试听课 3：引流课
          "courseName": state.name, //课程名称
          "page": state.pagination.page, //开始页
          "size": state.pagination.pageSize //每页显示的记录数
        }
      }else{
        url = '/dwart/external/smallProgram/queryOpenContentConfiguration'
        data = {
          "courseName": state.name, //课程名称
          "page": state.pagination.page, //开始页
          "size": state.pagination.pageSize //每页显示的记录数
        }
      }
      const r = await server.post(url, data)
      state.loading = false;
      state.pagination.itemCount = parseInt(r.count)
      state.data = r.entity;
    }

    const methods = {
      onPageChange: (page) => {
        state.pagination.page = page;
        getTable()
      },
      onPageSizeChange: (pageSize) => {
        state.pagination.pageSize = pageSize;
        getTable()
      },
      initTable: () => {
        state.pagination.page = 1;
        state.pagination.pageSize = 10;
        getTable();
      }
    }

    return {
      ...toRefs(state),
      ...methods,
      columns: props.isCourse? columns1: columns2,
    }
  },
  methods: {
    async init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.CourseRelate {

}
</style>
