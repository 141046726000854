<template>
  <div class="MiniLinkSettings">
    <n-tabs default-value="1">
      <n-tab-pane name="1" tab="课程配置" display-directive="show">
        <course-setting/>
      </n-tab-pane>
      <n-tab-pane name="2" tab="内容配置" display-directive="show:lazy">
        <content-setting/>
      </n-tab-pane>
    </n-tabs>
  </div>
</template>

<script>
import CourseSetting from "../../components/Mini/CourseSetting";
import ContentSetting from "../../components/Mini/ContentSetting";
export default {
  name: "MiniLinkSettings",
  components: {ContentSetting, CourseSetting},
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {

    },
  },
}
</script>

<style lang="scss" scoped>
.MiniLinkSettings {

}
</style>
