const a = {
  dwurl: process.env.NODE_ENV === 'production'? 'http://img.dwhs.cn/': 'http://dwart-test2.oss-cn-zhangjiakou.aliyuncs.com/',
  params: {
    region: "cn-zhangjiakou",
    accessKeyId: "LTAITB73t0NKMV88",
    accessKeySecret: "9C3RJ5IBIJu4Y3yl4PhipJIGv4qC1J",
    endpoint: "http://oss-cn-zhangjiakou.aliyuncs.com",
    bucket: process.env.NODE_ENV === 'production'?'dwart-public': 'dwart-test2'
  },
}

export default a;
