import server from "./api";

const downLoadFile = async(url, data, fileName, cb, method = 'get')=>  {
  cb && cb.start();
  const r = await server({
    method,
    url,
    responseType: "blob",
    data: method === 'get'? undefined: data,
    params: method === 'get'?data: undefined,
  })
  saveFile(r, fileName)
  cb && cb.finish();
}

const saveFile = (content, fileName) =>{
  const blob = new Blob([content]);
  if ("download" in document.createElement("a")) {
    // 非IE下载
    const eLink = document.createElement("a");
    eLink.download = fileName;
    eLink.style.display = "none";
    eLink.href = URL.createObjectURL(blob);
    document.body.appendChild(eLink);
    eLink.click();
    URL.revokeObjectURL(eLink.href); // 释放URL 对象
    document.body.removeChild(eLink);
  } else {
    // IE10+下载
    // navigator.msSaveBlob(blob, fileName);
  }
}

export default downLoadFile;
