<template>
  <div class="PictureList">
    <div class="box" v-for="(i, k) in list" :key="k" v-viewer>
      <div><img :src="i.pictureUrl" alt="" class="banner-img"></div>
      <div class="banner-btn">
        <div>
          <span style="width: 120px">跳转链接：</span>
          <n-input type="text" size="small" placeholder="" :value="i.linkName" disabled/>
        </div>
        <div style="margin-top: 10px; display: flex; justify-content: space-between">
          <div>
            <n-button text type="primary" @click="onEdit(i, k)">编辑</n-button>
            <n-button text type="primary" style="margin-left: 25px" @click="onDel(i, k)">删除</n-button>
          </div>
          <div>
            <n-button text type="primary" :disabled="k === 0" @click="onMoveLeft(i, k)">左移</n-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";


export default defineComponent({
  name: "PictureList",
  data() {
    return {}
  },
  props: {
    list: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  mounted() {
    this.init();
  },
  setup(props, {emit}){
    const onEdit = (item, index) => {
      console.log(props);
      emit('edit', item);
    }
    const onDel = (item, index) => {
      // let {list} = props;
      // list.splice(index, 1);
      // emit('update:list', list);
      emit('del', item);
    }
    const onMoveLeft = (item, index) => {
      // list.splice(index, 1);
      // list.splice(index -1 , 0, item);
      // emit('update:list', list);
      emit('move-left', item);
    }
    return {
      onEdit,
      onDel,
      onMoveLeft,
    }
  },
  methods: {
    async init() {

    },
  },
})
</script>

<style lang="scss" scoped>
.PictureList {
  display: flex;
  flex-wrap: wrap;
  .box {
    margin: 10px;
    .banner-img{
      width: 290px;
      height: 140px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
  .banner-btn{
    width: 100%;
    height: 70px;
    &>div{
      display: flex;
    }
  }
}
</style>
