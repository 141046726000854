<template>
  <div class="CourseSetting">
    <n-space vertical>
      <n-button type="primary" size="small" @click.prevent="onAddOne">新增课程</n-button>
      <n-card :bordered="false" :segmented="{content: 'soft'}">
        <template #header>
          <div style="text-align: center">
            课程列表
          </div>
        </template>
        <template #default>
          <n-space vertical>
            <n-data-table
                size="small"
                :columns="columns"
                :data="data"
                :loading="loading"
                :row-key="rowKey"
            />
            <div style="display: flex; justify-content: flex-end">
              <n-pagination :page-sizes="pagination.pageSizes" :page-size="pagination.pageSize"
                            :item-count="pagination.itemCount" show-quick-jumper show-size-picker
                            :prefix="pagination.prefix"
                            :on-update:page="onPageChange"
                            :on-update:page-size="onPageSizeChange"
              />
            </div>
          </n-space>
        </template>
      </n-card>
    </n-space>


    <n-drawer v-model:show="visible" :width="700" placement="left" :mask-closable="false">
      <n-drawer-content>
        <template #header>
          <span style="font-weight: bold">
            新增/编辑
          </span>
        </template>
        <template #footer>
          <n-space>
            <n-button type="primary" size="small" :loading="drawerLoading" @click="onSave">保存</n-button>
            <n-button strong tertiary size="small" @click.prevent="visible = false">取消</n-button>
          </n-space>
        </template>
        <template #default>
          <n-form
              size="small"
              :model="form"
              ref="formRef"
              label-placement="left"
              label-align="left"
              require-mark-placement="left"
              :label-width="160"
              :style="{maxWidth: '640px'}"
              :rules="rules"
          >
            <n-form-item label="选择课程类型" path="courseType">
              <n-select
                  placeholder="选择课程类型"
                  :options="courseTypeList"
                  v-model:value="form.courseType"
              >
              </n-select>
            </n-form-item>
            <n-collapse-transition :show="form.courseType && form.courseType !== '2'">
              <n-form-item label="课程单价" path="coursePrice" v-if="form.courseType && form.courseType !== '2'">
                <n-input-number v-model:value="form.coursePrice" placeholder="请输入课程价格" :min="0" :default-value="0"
                                :show-button="false" clearable>
                  <template #prefix>￥</template>
                </n-input-number>
              </n-form-item>
            </n-collapse-transition>

            <n-form-item label="课程名称" path="courseName">
              <n-input v-model:value="form.courseName" clearable></n-input>
            </n-form-item>

            <n-form-item label="截止时间" path="endTime">
              <n-date-picker clearable v-model:value="form.endTime" type="datetime" format="yyyy-MM-dd HH:mm"/>
            </n-form-item>

            <n-form-item label="学生资料" path="" required>
              <n-space vertical>
                <n-space v-for="(i, k) in form.studentInfo" :key="k">
                  <span>{{ i.title }}</span>
                  <n-radio-group v-model:value="i.type">
                    <n-space>
                      <n-radio value="1">必填</n-radio>
                      <n-radio value="2">选填</n-radio>
                      <n-radio value="3">不显示</n-radio>
                    </n-space>
                  </n-radio-group>
                </n-space>
              </n-space>
            </n-form-item>
            <n-form-item path="mainSrc">
              <template #label>
                <div>活动主图（1张）</div>
                <div>（750*300）</div>
              </template>
              <div v-if="form.mainSrc">
                <n-space vertical>
                  <img :src="form.mainSrc" alt="" style="width: 190px; height: 100px; object-fit: cover" v-viewer>
                  <n-button type="primary" text @click="form.mainSrc = null">删除</n-button>
                </n-space>
              </div>
              <n-upload
                  v-else
                  :action="uploadInfo.dwurl"
                  :data="dataTrans"
                  accept="image/*"
                  :on-finish="uploadFinish"
                  :on-before-upload="beforeUpload"
                  name="unfile"
                  :show-file-list="false"
              >
                <n-upload-dragger>
                  <div>
                    <n-icon size="48" :depth="3">
                      <cloud-upload-outlined/>
                    </n-icon>
                  </div>
                  <n-text style="font-size: 16px;">点击或者拖动文件到该区域来上传</n-text>
                  <br/>
                  <n-text style="font-size: 12px; color: #FF9900">大小不超过2M</n-text>
                </n-upload-dragger>

              </n-upload>
            </n-form-item>

            <n-form-item label="详情图(多张)" path="detailSrc">
              <n-space vertical>
                <div>
                  <n-space>
                    <div v-for="(i, k) in form.detailSrc" :key="k" v-viewer>
                      <n-space vertical>
                        <img :src="i" alt="" style="width: 190px; height: 100px; object-fit: cover">
                        <n-button type="primary" text @click="form.detailSrc.splice(k, 1)">删除</n-button>
                      </n-space>
                    </div>
                  </n-space>
                </div>
                <n-upload
                    :action="uploadInfo.dwurl"
                    :data="dataTrans"
                    accept="image/*"
                    :on-finish="uploadFinish2"
                    name="unfile"
                    multiple
                    :show-file-list="false"
                >
                  <n-upload-dragger>
                    <div>
                      <n-icon size="48" :depth="3">
                        <cloud-upload-outlined/>
                      </n-icon>
                    </div>
                    <n-text style="font-size: 16px;">点击或者拖动文件到该区域来上传</n-text>
                    <br/>
                    <n-text style="font-size: 12px; color: #FF9900">大小不超过2M</n-text>
                  </n-upload-dragger>

                </n-upload>
              </n-space>
            </n-form-item>

            <n-form-item label="启动状态">
              <n-switch v-model:value="form.status">
              </n-switch>
            </n-form-item>
          </n-form>
        </template>
      </n-drawer-content>
    </n-drawer>
  </div>
</template>

<script>
import {defineComponent, h, onMounted, reactive, ref, toRefs, watch} from "vue";
import {NButton, NSwitch, useLoadingBar, useMessage} from "naive-ui";
import uploadInfo from "../../utils/upload";
import moment from "moment";
import {CloudUploadOutlined} from '@vicons/antd'
import server from "../../utils/api";

export default defineComponent({
  name: "CourseSetting",
  components: {CloudUploadOutlined},
  data() {
    return {
      uploadInfo: uploadInfo,
    }
  },
  mounted() {
    this.init();
  },
  setup() {
    let formRef = ref(null)
    const message = useMessage()
    const loadingBar = useLoadingBar()
    const columns = [
      {
        title: '课程名称',
        key: 'courseName',
        align: 'center'
      },
      {
        title: '课程类型',
        key: 'courseType',
        align: 'center'
      },
      {
        title: '活动时间',
        key: 'activityTime',
        align: 'center',
      },
      {
        title: '创建时间',
        key: 'createTime',
        align: 'center',
      },
      {
        title: '单价',
        key: 'coursePrice',
        align: 'center',
      },
      {
        title: '状态',
        key: 'isOpen',
        align: 'center',
        render(row) {
          return h(
              NSwitch,
              {
                value: row.isOpen,
                "onUpdate:value": function (e) {
                  onSwitchChange(row, e)
                },
                size: 'small',
                loading: row.loading,
              },
          )
        }
      },
      {
        title: '操作',
        key: 'address',
        align: 'center',
        render(row) {
          return h(
              NButton,
              {
                size: 'small',
                text: true,
                type: 'primary',
                onClick: () => onEdit(row),
              },
              {
                default: () => '编辑'
              }
          )
        }
      }
    ]
    const rules = {
      courseType: {required: true, message: '请选择课程类型', trigger: ['blur', 'change'],},
      courseName: {required: true, message: '请输入课程名称', trigger: ['blur', 'change'],},
      endTime: {required: true, message: '请选择结束日期', trigger: ['blur', 'change'], type: 'number',},
      mainSrc: {required: true, message: '请上传活动主图'},
      detailSrc: {required: true, message: '请上传活动详情图', type: 'array'}
    }
    const onEdit = (row) => {
      getEditInfo(row);
    }
    const onSave = (e) => {
      e.preventDefault();
      formRef.value.validate(err=> {
        console.log(err)
        if (!err){
          getSaveAddOrEdit();
        }
      })
    }

    const dataTrans = ({file}) => {
      return {
        key: `mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`,
        id: file.id,
        name: file.name,
        type: file.type,
        lastModifiedDate: file.file.lastModifiedDate,
        size: file.file.size,
        ...uploadInfo.params,
        file: file.file,
      }
    }
    const uploadFinish = ({file, event}) => {
      file.url = `${uploadInfo.dwurl}mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`
      state.form.mainSrc = file.url;
    }
    const uploadFinish2 = ({file, event}) => {
      file.url = `${uploadInfo.dwurl}mini/${moment().format('YYYY-MM-DD')}/${file.id}_${file.name}`
      console.log(file.url);
      state.form.detailSrc.push(file.url)
    }
    const beforeUpload = ({file, fileList}) => {
      if (fileList.length === 1) {
        return false
      }
    }
    let state = reactive({
      pagination: {
        page: 1,
        pageSize: 10,
        itemCount: 0,
        pageSizes: [10, 20, 50, 100],
        prefix({itemCount}) {
          return `合计${itemCount}条`
        }
      },
      rowKey(rowData) {
        return rowData.column1
      },
      loading: false,
      drawerLoading: false,
      data: [],
      visible: false,
      form: {
        id: null,
        courseType: null,
        courseName: null,
        coursePrice: 0,
        endTime: null,
        studentInfo: [
          {title: '宝贝姓名', type: '1'},
          {title: '联系电话', type: '1'},
          {title: '宝贝性别', type: '2'},
          {title: '宝贝年龄', type: '2'},
          // {title: '居住小区', type: '3'},
        ],
        mainSrc: null,
        detailSrc: [],
        status: true,
      },
      courseTypeList: [
        {label: '体验课', value: '1'},
        {label: '试听课', value: '2'},
        {label: '引流课', value: '3'},
      ],
    })

    onMounted(async () => {
      await getTable();
    })

    const getTable = async () => {
      state.loading = true;
      const r = await server.post('/dwart/external/smallProgram/queryCourseConfiguration', {
        page: state.pagination.page,
        size: state.pagination.pageSize,
      })
      state.loading = false;
      state.pagination.itemCount = parseInt(r.count)
      state.data = r.entity;
    }
    const onSwitchChange = async (row, e) => {
      row.loading = true;
      const r = await server.post('/dwart/external/smallProgram/switchCourseConfiguration', {
        courseId: row.courseId,
        isOpen: e,
      });
      if (r) {
        row.isOpen = e;
      }
      row.loading = false;
    }

    const getEditInfo = async (row) => {
      loadingBar.start();
      const r = await server.get('/dwart/external/smallProgram/echoCourseConfiguration', {params: {courseId: row.courseId,}})
      state.form = {
        id: r.courseId,
        courseType: r.courseType.toString(),
        courseName: r.courseName,
        coursePrice: r.coursePrice,
        endTime: parseInt(moment(r.endTime).format('x')),
        studentInfo: r.studentDataList.map(v => {
          return {title: v.title, type: v.type.toString()}
        }),
        mainSrc: r.picture,
        detailSrc: r.detailsPicture,
        status: r.isOpen,
      }
      loadingBar.finish();
      state.visible = true
    }

    const getSaveAddOrEdit = async () => {
      state.drawerLoading = true;
      const {id, courseType, courseName, coursePrice, endTime, studentInfo, mainSrc, detailSrc, status} = state.form;
      const r = await server.post('/dwart/external/smallProgram/addCourseConfiguration', {
        "courseId": id,//课程配置id
        "courseType": courseType,//课程类型 1：体验课 2：试听课 3：引流课
        "coursePrice": coursePrice,//课程单价
        "courseName": courseName,//课程名称
        "endTime": moment(endTime).format('YYYY-MM-DD HH:mm:ss'),//结束时间
        "studentDataList": studentInfo,
        "picture": mainSrc,//图片
        "detailsPicture": detailSrc,
        "isOpen": status//是否开启  true：开启  false：关闭
      })
      state.drawerLoading = false;
      state.visible = false;
      if (r){
        message.success('上传成功')
        await getTable();
      }
    }

    const onPageChange = (page) => {
      state.pagination.page = page;
      getTable()
    }
    const onPageSizeChange = (pageSize) => {
      state.pagination.pageSize = pageSize;
      getTable()
    }
    const onAddOne = () => {
      state.form = {
        id: null,
        courseType: null,
        courseName: null,
        coursePrice: 0,
        endTime: null,
        studentInfo: [
          {title: '宝贝姓名', type: '1'},
          {title: '联系电话', type: '1'},
          {title: '宝贝性别', type: '2'},
          {title: '宝贝年龄', type: '2'},
          // {title: '居住小区', type: '3'},
        ],
        mainSrc: null,
        detailSrc: [],
        status: true,
      }
      state.visible = true
    }

    return {
      formRef,
      ...toRefs(state),
      columns,
      rules,
      dataTrans,
      uploadFinish,
      uploadFinish2,
      beforeUpload,
      onPageChange,
      onPageSizeChange,
      onAddOne,
      onSave,
    }
  },
  methods: {
    async init() {

    },
  },
})
</script>

<style lang="scss" scoped>
.CourseSetting {
  margin-top: 10px;
}
</style>
